[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: '\e934';
}
.icon-i_question:before {
  content: '\e933';
}
.icon-adgroup_settings:before {
  content: '\e932';
}
.icon-google-plus:before {
  content: '\e930';
}
.icon-facebook:before {
  content: '\e931';
}
.icon-eye-open:before {
  content: '\e92f';
}
.icon-eye:before {
  content: '\e924';
}
.icon-warning:before {
  content: '\e92e';
}
.icon-i-ad:before {
  content: '\e92d';
}
.icon-remove:before {
  content: '\e92c';
}
.icon-info:before {
  content: '\e92b';
}
.icon-camera-to-take-photos:before {
  content: '\e92a';
}
.icon-logo-edit:before {
  content: '\e929';
}
.icon-closed-eye:before {
  content: '\e928';
}
.icon-image-settings:before {
  content: '\e927';
}
.icon-arrow_right:before {
  content: '\e90f';
}
.icon-stats:before {
  content: '\e91c';
}
.icon-money:before {
  content: '\e91d';
}
.icon-leads:before {
  content: '\e923';
}
.icon-dollar-small:before {
  content: '\e925';
}
.icon-click:before {
  content: '\e926';
}
.icon-double-arrow-left:before {
  content: '\e91a';
}
.icon-double-arrow-right:before {
  content: '\e91b';
}
.icon-success:before {
  content: '\e919';
}
.icon-arrow_left:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e909';
}
.icon-arrow-down:before {
  content: '\e90d';
}
.icon-link:before {
  content: '\e912';
}
.icon-clock:before {
  content: '\e913';
}
.icon-calendar:before {
  content: '\e914';
}
.icon-logout:before {
  content: '\e916';
}
.icon-billings:before {
  content: '\e917';
}
.icon-search:before {
  content: '\e918';
}
.icon-comment:before {
  content: '\e91f';
}
.icon-like:before {
  content: '\e920';
}
.icon-share:before {
  content: '\e921';
}
.icon-save:before {
  content: '\e922';
}
.icon-ad:before {
  content: '\e91e';
}
.icon-edit:before {
  content: '\e915';
}
.icon-minus:before {
  content: '\e910';
}
.icon-check:before {
  content: '\e911';
}
.icon-users:before {
  content: '\e902';
}
.icon-profile:before {
  content: '\e903';
}
.icon-reports:before {
  content: '\e904';
}
.icon-listings:before {
  content: '\e905';
}
.icon-buildings:before {
  content: '\e906';
}
.icon-campaigns:before {
  content: '\e907';
}
.icon-dashboard:before {
  content: '\e908';
}
.icon-collections:before {
  content: '\e935';
}
.icon-plus:before {
  content: '\e90b';
}
.icon-delete:before {
  content: '\e90c';
}
.icon-bell:before {
  content: '\e90e';
  color: #637381;
}
.icon-dollar:before {
  content: '\e901';
}
.icon-exit-outline:before {
  content: '\e90a';
}
